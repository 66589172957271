import { Toast } from 'vant';
import { getToken } from "@/utils/auth";
import store from '@/store'
import defaultImChat from '@/imChat'

const { baseChatUrl, systemId } = defaultImChat

// 声明WebSocket对象
// http://xxx -> ws://xxx
const wsBaseURL =
    baseChatUrl.replace('http', 'ws');

// 声明消息访问路径
const messageUrl = "/websocket/im/message";

//创建基本消息WebSocket对象
export function baseMessageWebsocket() {
  return createWebSocket(messageUrl);
}

// 创建WebSocket对象
function createWebSocket(url) {
  //这里获取路径可能有问题
  let result = new WebSocket(
    wsBaseURL + url + '?token=' + getToken() + '&systemId=' + systemId
  );
  if (!result) {
    // 创建对象出现异常!
    console.log("创建对象出现异常!")
    return null;
  }
  result.onopen = function (event) {
    //打开连接
    console.log("打开连接")
  };
  result.onclose = function (event) {
    //关闭连接
    console.log("关闭连接")
  };
  result.onerror = function (event) {
    //WebSocket出现异常
    console.log("出现异常")
    //Toast.fail('出现异常!');
  };
  return result;
}

//获取当前登录的用户Id
export function getCurrentUserId() {
  return store.getters && store.getters.userId;
}
