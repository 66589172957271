<template>
    <div class="customer-chat">
        <!-- 头部 -->
        <van-nav-bar
                title="云楚新能源客服"
                left-arrow
                @click-left="onClickLeft"
                fixed
                :z-index="100"
                class="dark_bg"
        />
        <p class="current-time">
            <span>{{currentTime}}</span>
        </p>
        <main class="chat-content">
            <van-pull-refresh
                    v-model="loading"
                    @refresh="onRefresh"
                    :success-text="successText"
                    :loosing-text='loosingText'
                    :success-duration="800"
            >
                <section class="chat-list" ref="chatWindow">
                    <!-- 客服主动提示消息 -->
                    <ul class="customer-server-msg" v-if="welcome">
                        <li class="header-img">
                            <span class="iconfont icon-kefu"></span>
                        </li>
                        <li class="msg-info">
                            <p class="msg-info-con">
                                <span v-html="welcome"></span>
                                <span class="msg-tip"></span>
                            </p>
                            <p class="msg-info-time left">{{currentTime}}</p>
                        </li>
                    </ul>
                    <ul class="customer-server-msg" v-if="score.length||trans.length||aftermarket.length">
                        <li class="header-img">
                            <span class="iconfont icon-kefu"></span>
                        </li>
                        <li class="msg-info server-list">
                            <p class="msg-info-con">
                                <label v-if="score.length">
                                    <em>关于积分兑换</em>
                                    <span
                                            v-for="sinfo in score"
                                            :key="sinfo.id"
                                            @click="goArticleDetail(sinfo.id)"
                                    >{{sinfo.title}}</span>
                                </label>
                                <label v-if="trans.length">
                                    <em>关于发货时间</em>
                                    <span
                                            v-for="tinfo in trans"
                                            :key="tinfo.id"
                                            @click="goArticleDetail(tinfo.id)"
                                    >{{tinfo.title}}</span>
                                </label>
                                <label v-if="aftermarket.length">
                                    <em>关于售后服务</em>
                                    <span
                                            v-for="ainfo in aftermarket"
                                            :key="ainfo.id"
                                            @click="goArticleDetail(ainfo.id)"
                                    >{{ainfo.title}}</span>
                                </label>
                                <span class="msg-tip"></span>
                            </p>
                            <p class="msg-info-time left">{{currentTime}}</p>
                        </li>
                    </ul>
                    <!-- 客服与客户对话列表 -->
                    <ul
                            v-for="(item,index) in messageList"
                            :key="item.id + index"
                            :class="{'customer-server-msg':!messageIsSelf(item),'customer-msg': messageIsSelf(item)}"
                    >
                        <li class="header-img" v-if="!messageIsSelf(item)">
                            <span class="iconfont icon-kefu"></span>
                        </li>
                        <li class="msg-info">
                            <p class="msg-info-con" :class="{'customer-bgc': messageIsSelf(item)}">
                                <span v-text="item.content"></span>
                                <span class="msg-tip"></span>
                            </p>
                            <p class="msg-info-time" :class="{'left':!messageIsSelf(item)}">
                                {{item.operateTime}}</p>
                        </li>
                        <li class="header-img" v-if="messageIsSelf(item)">
                            <!-- <span class="iconfont icon-touxiang1" style="color:#D5E270"></span> -->
                            <img src="../../assets/images/qqf.png" alt/>
                        </li>
                    </ul>
                </section>
            </van-pull-refresh>
        </main>
        <!-- 底部 -->
        <div class="chat-footer">
            <van-search
                    v-model="sendMessageObj.content"
                    show-action
                    left-icon
                    @search="sendMessage"
                    class="dark_bg dark_input"
                    maxlength='100'
            >
                <span slot="action" @click="sendMessage" class="dark_text">发送</span>
            </van-search>
        </div>
    </div>
</template>

<script>
    import {NavBar, Search, List, PullRefresh} from "vant";

    import {baseMessageWebsocket} from '@/utils/websocket'
    import {getKhNotReadMessageCount, getChatHistory, markKhMessageAlreadyRead} from '@/api/customerChat'

    export default {
        data() {
            return {
                chatValue: "",
                currentTime: this.Base.currentTime(),
                loading: true,
                currentPage: 1,
                totalPage: Number,
                chatList: [], // 消息列表
                utf16toEntities: this.Base.utf16toEntities,
                goArticleDetail: this.Base.goArticleDetail,
                successText: "刷新成功",
                loosingText:'',
                welcome: "", // 欢迎消息
                trans: [], // 发货时间
                score: [], // 积分兑换
                aftermarket: [],// 售后服务
                //=============================
                websocket: null,
                //发送消息体
                sendMessageObj: {
                    //消息内容
                    content: null,
                },
                //查询消息参数
                queryMessageParams: {
                    pageNum: 1,
                    pageSize: 10,
                },
                //消息总数
                messageTotal: 0,
                //消息列表
                messageList: [
                    {
                        id: null,
                        fromId: null,
                        sendId: null,
                        content: null,
                        operateTime: null,
                    }
                ]
            };
        },
        components: {
            [NavBar.name]: NavBar,
            [Search.name]: Search,
            [List.name]: List,
            [PullRefresh.name]: PullRefresh
        },
        created() {
            //连接websocket
            this.websocket = baseMessageWebsocket();
            if (!this.websocket) {
                //TODO 出现异常 弹出去
            }
            let that = this;
            this.websocket.onmessage = function (event) {
                let result = JSON.parse(event.data);
                // console.log(result)
                if (result.code === 200) {
                    //200 消息发送成功
                    that.initMessageQuery();
                } else if (result.code === 300) {
                    //300 收到消息
                    that.initMessageQuery();
                } else {
                    //500 异常
                    that.$toast.fail(result.msg);
                }
            };
        },
        mounted() {
            //查询消息
            this.initMessageQuery();
        },
        beforeDestroy() {
            //释放websocket
            if (this.websocket) {
                this.websocket.close();
                this.websocket = null;
            }
        },
        methods: {
            //判断消息
            messageIsSelf(message) {
                //TODO 报null错误？
                if (message && message.fromId) {
                    return message.fromId.substr(0, 3) === 'KH-';
                }
            },
            //返回
            onClickLeft() {
                this.$router.back();
            },
            //初始化消息查询
            async initMessageQuery() {
                //清空查询参数
                this.clearQueryMessageParams();
                //查询列表
                await this.getMessageList();
                // 滑动到最后
                this.scrollToBottom();
            },
            //清空消息查询
            clearQueryMessageParams() {
                this.queryMessageParams = {
                    pageNum: 1,
                    pageSize: 10,
                };
            },
            //获取聊天列表
            getMessageList() {
                // console.log(this.queryMessageParams.pageNum);
                getChatHistory(this.queryMessageParams).then(res => {
                    console.log(res);
                    let response = res.data;
                    if (response.code === 200) {
                        if (this.queryMessageParams.pageNum === 1) {
                            //如果是第一页 直接赋值
                            this.messageList = response.data.rows;
                            this.totalPage =  response.data.totalPage
                        } else {
                            //否则拼接在原数组前面
                            this.messageList = response.data.rows.concat(this.messageList);
                            this.loading = false
                        }
                        this.messageTotal = response.data.total;
                        if (this.messageTotal === this.messageList.length) {
                            //TODO 设置没有更多了
                        }
                        //标记消息已读
                        this.markMessageRead();
                    } else {
                        this.$toast.fail(response.msg);
                        //TODO 设置没有更多了
                    }
                });
            },
            //标记消息已读
            markMessageRead() {
                markKhMessageAlreadyRead();
            },
            // 刷新
            onRefresh() {
                this.queryMessageParams.pageNum++;
                if( this.queryMessageParams.pageNum >= this.totalPage +1){
                    this.loading = false
                    this.successText = '没有更多了'
                    return
                }
                this.getMessageList();
            },
            // 发送消息
            sendMessage() {
                // 不让发空格
                let value = this.sendMessageObj.content.trim();
                if (!value) {
                    this.$toast.fail("请输入发送内容!");
                    return false;
                }
                if (this.websocket) {
                    this.websocket.send(JSON.stringify(this.sendMessageObj));
                    this.sendMessageObj.content = null;
                } else {
                    this.$toast.fail('未连接到服务器');
                }
            },
            scrollToBottom() {
                this.$nextTick(() => {
                    let container = this.$el.querySelector(".chat-content");
                    container.scrollTop = container.scrollHeight;
                });
            }
        },
    };
</script>

<style lang="less" scoped>
    .customer-chat {
        width: 100%;
        box-sizing: border-box;
        padding: 1.28rem /* 64/50 */ 0 1.28rem /* 64/50 */;

        .van-nav-bar {
            color: #373737;
            letter-spacing: 1px;

            .van-icon {
                color: #ccc;
                font-size: .44rem /* 22/50 */;
            }
        }

        & > p.current-time {
            width: 100%;
            box-sizing: border-box;
            text-align: center;
            font-size: .24rem /* 12/50 */;
            color: #9a9899;
            padding: .16rem /* 8/50 */ 0 0;
            // background-color: #f0f0f0;
            position: fixed;
            top: 46px;
            left: 0;
            z-index: 100;

            span {
                background-color: #e7e3e4;
                padding: .1rem /* 4.8/50 */ .56rem /* 28/50 */;
                border-radius: .06rem /* 3/50 */;
                display: inline-block;
            }
        }

        .chat-content {
            padding: .16rem /* 8/50 */;

            .chat-list {
                width: 100%;
                box-sizing: border-box;

                ul {
                    width: 100%;
                    box-sizing: border-box;
                    display: flex;
                    align-items: flex-start;
                    margin: .32rem /* 16/50 */ 0 0;

                    li {
                        font-size: .24rem /* 12/50 */;
                        box-sizing: border-box;

                        &.header-img {
                            width: 10%;
                            height: .7rem /* 35/50 */;
                            line-height: .7rem /* 35/50 */;
                            background-color: #fff;
                            text-align: center;
                            border-radius: .06rem /* 3/50 */;

                            & > span {
                                font-size: .56rem /* 28/50 */;
                            }

                            & > img {
                                width: 100%;
                                height: 100%;
                                border-radius: .06rem /* 3/50 */;
                            }
                        }

                        &.msg-info {
                            width: 80%;

                            .msg-info-con {
                                line-height: .38rem /* 19/50 */;
                                background-color: #fff;
                                padding: .1rem /* 4.8/50 */;
                                border-radius: .1rem /* 4.8/50 */;
                                position: relative;
                                display: inline-block;

                                .msg-tip {
                                    display: inline-block;
                                    width: 0;
                                    height: 0;
                                    border: 4px solid transparent;
                                    position: absolute;
                                }

                                &.customer-bgc {
                                    background-color: #ffbebf;
                                }
                            }

                            .msg-info-time {
                                margin: .16rem /* 8/50 */ 0 0;
                                font-size: .22rem /* 11/50 */;
                                color: #a6a6a6;
                                text-align: right;

                                &.left {
                                    text-align: left;
                                }
                            }
                        }

                        &.server-list {
                            em {
                                display: block;
                                font-weight: bold;
                            }

                            span {
                                text-indent: .32rem /* 16/50 */;
                                text-decoration: underline;
                                display: block;
                                color: rgb(101, 173, 201);
                            }
                        }
                    }

                    &.customer-server-msg {
                        .msg-info {
                            margin: 0 .72rem /* 36/50 */ 0 .24rem /* 12/50 */;

                            .msg-info-con {
                                word-wrap: break-word;
                                word-break: break-all;

                                .msg-tip {
                                    border-right: 9px solid #fff;
                                    top: 5px;
                                    left: -12px;
                                }
                            }
                        }
                    }

                    &.customer-msg {
                        justify-content: flex-end;

                        .msg-info {
                            margin: 0 .24rem /* 12/50 */ 0 .76rem /* 38/50 */;
                            text-align: right;

                            .msg-info-con {
                                text-align: left;
                                word-wrap: break-word;
                                word-break: break-all;

                                .msg-tip {
                                    border-left: 9px solid #ffbebf;
                                    top: 5px;
                                    right: -12px;
                                }
                            }
                        }
                    }
                }
            }
        }

        .chat-footer {
            width: 100%;
            box-sizing: border-box;
            position: fixed;
            left: 0;
            bottom: 0;
            z-index: 100;
        }
    }
</style>