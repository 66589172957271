import axios from "axios";
import { getToken } from "@/utils/auth";
import defaultImChat from '@/imChat'

const { baseChatUrl, systemId } = defaultImChat

axios.defaults.headers['Content-Type'] = 'application/json;charset=utf-8'
// 创建axios实例
const service = axios.create({
    //单独提出路径 axios中请求配置有baseURL选项，表示请求URL公共部分
    baseURL: baseChatUrl,
    // 超时
    timeout: 1000000
})

// request拦截器
service.interceptors.request.use(config => {
    //设置请求头
    config.headers['token'] = getToken()
    config.headers['systemId'] = systemId
    return config
}, error => {
    console.log(error)
    Promise.reject(error)
})

const prefix = "/openApi/im/message/client"

// 查某客户的未读消息总数
export function getKhNotReadMessageCount() {
    return service({
        url: prefix + '/notReadMessageCount',
        method: 'get'
    })
}

// 查某客户的历史消息 参数 分页
export function getChatHistory(query) {
    return service({
        url: prefix + '/chatHistory',
        method: 'get'
    })
}

// 标记客户接收的消息已读
export function markKhMessageAlreadyRead() {
    return service({
        url: prefix + '/markMessageAlreadyRead',
        method: 'put'
    })
}
