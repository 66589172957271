module.exports = {

    /**
     * 客服基本链接
     */
    // baseChatUrl: 'http://47.107.71.240:8375/youngbook',
    baseChatUrl: 'http://120.77.11.127:8375/youngbook',

    /**
     * 第三方系统注册Id
     */
    systemId: '111',//生产机
    // systemId: '113',//测试机

}
